import { useState } from "react";
import { useQuery } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";

/**
 * @param userId
 * @param index
 * @param postsPerPage
 * @returns
 */
export function useLoadBrokenPages(
  userIds: any,
  postsPerPage: number,
  filter: any,
  usernames: any
) {
  async function getCount() {
    let feedCount;
    let postIds;

    const { data, error } = await supabase.rpc("get_brokenlinkstablecount", {
      query_string: filter ? [`%%${filter}%%`] : [],
      userid: userIds,
      username: usernames || "",
      index_type: "archived",
    });

    // const { data, error } = await supabase.rpc("get_brokenlinkscountsss", {
    //   query_string:filter? [`%%${filter}%%`]:[],
    //   userid: userIds,

    //   username: usernames,

    //   index_type: "archived",
    // });
    feedCount = data?.[0]?.count;
    return {
      totalPages: Math.ceil(feedCount / postsPerPage),
      feedCount: feedCount,
      postIds: postIds || null,
    };
  }

  return useQuery(
    ["paginatedBrokenPosts", filter, userIds, postsPerPage, usernames],
    getCount,
    {}
  );
}
