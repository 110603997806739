import { Input, Modal, message } from "antd";
import React, { useState } from "react";
import {
  getUserData,
  signupUser,
  signupUserFolder,
} from "../../../../SupabaseServices/UserDatabase";
import { useAuth } from "../../../../hooks/useAuth";
import { User } from "@supabase/supabase-js";
import { supabase } from "../../../../Config/SupabaseClient";
import Papa from "papaparse";
import axios from "axios";

interface CustomFileInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  webkitdirectory?: string;
  mozdirectory?: string;
  directory?: string;
}
const UsernameUpload = (props: any) => {
  const { showNewCreatorModal, user_id, setShowNewCreatorModal } = props;
  const { user } = useAuth();
  const [username, setUsername] = useState("");
  const creatorUserId = "";
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [messageApi, contextHolder] = message.useMessage();

  const handleCancel = () => {
    props.setShowNewCreatorModal(false);
    setSelectedFiles([]);
  };

  async function handleOk() {
    console.log("fdf", username, selectedUser, creatorUserId, user);
    const res: any = await signupUserFolder(
      username,
      creatorUserId,
      user as User
    );
    if (res.error) {
      // setError(true);
      // setErrorMsg(res.message);
      // setLoading(false);
      alert("Error Adding Creator!");
      return;
    } else {
      // success("Creator Added Successfully!")
      const { data: user } = await supabase
        .from("user")
        .select("*")
        .eq("username", username);

      alert("User created");

      setTimeout(()=>{
        const profileSetupUrl = `https://dev.thesearchbubble.com/profilesetup`;

        axios.post(
          profileSetupUrl,
          { id: user?.[0]?.user_id, name: username, from: "content" },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );
        const highlightSetupUrl = `https://dev.thesearchbubble.com/highlightsetup`;
        // const highlightSetupUrl = `http://localhost:3001/highlightsetup`;
  
        axios.post(
          highlightSetupUrl,
          { id: user?.[0]?.user_id, name: username, from: "content" },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );
        const demoSetupUrl = `https://dev.thesearchbubble.com/demosetup`;
        // const demoSetupUrl = `http://localhost:3001/demosetup`;
  
        const resThumbnail = axios.post(
          demoSetupUrl,
          { id: user?.[0]?.user_id, name: username, from: "content" },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );
      },2000)
      // const profileSetupUrl = `http://localhost:3001/profilesetup`;

      props.fetchUsers();
      setShowNewCreatorModal(false);
      // props.setSelectedUser(username)
      // props.setIsModalOpen(true)
    }
  }
  return (
    <div>
      <Modal
        title="Create new creator"
        open={showNewCreatorModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Create"
        // footer={null}
      >
        <p>Enter username </p>
        <Input type="text" onChange={(e: any) => setUsername(e.target.value)} />
        {/* <input
          type="file"
          multiple
          {...({
            webkitdirectory: "",
            mozdirectory: "",
            directory: "",
          } as CustomFileInputProps)}
          onChange={handleFileChange}
        /> */}
        {/* <button onClick={uploadFolder}>Create</button> */}
      </Modal>
    </div>
  );
};

export default UsernameUpload;
