import axios from "axios";
import { supabase } from "../Config/SupabaseClient";

export async function addProduct(
  name: any,
  url: any,
  image: any,
  user_id: any,
  platform: any,
  category: any,
  createdDate: any,
  usernames:string[]
) {
  function generateRandom12DigitNumber() {
    // Generate a random number between 100000000000 and 999999999999
    return (
      Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1)) +
      1000000000000000
    );
  }

  // Example usage:
  let randomNumber = generateRandom12DigitNumber();
  let combinedParts = [
    name || "",
    category || "", 
  ];

  // Filter out empty values and join them with a separator (e.g., space or comma)
  const combinedText = combinedParts.filter(Boolean).join(" ");
  const { data, error } = await supabase.from("product_bank").insert([
        //combined_text

    {
      name,
      url,
      image,
      user_id,
      product_id: randomNumber,
      platform:platform || 'MISC',
      category,
      created_at: createdDate, 
      combined_text:combinedText// Only include created_at if createdDate is defined
    },
  ]);
  if (error !== null) {
    function generateRandom12DigitNumber() {
      // Generate a random number between 100000000000 and 999999999999
      return (
        Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1)) +
        1000000000000000
      );
    }

    // Example usage:
    let randomNumber = generateRandom12DigitNumber();
    let combinedParts = [
      name || "",
      category || "", 
    ];
  
    // Filter out empty values and join them with a separator (e.g., space or comma)
    const combinedText = combinedParts.filter(Boolean).join(" ");
    const { data, error } = await supabase.from("product_bank").insert([
      {
        name,
        url,
        image,
        user_id,
        product_id: randomNumber,
        platform:platform || 'MISC',
        category,
        created_at: createdDate,
        combined_text:combinedText // Only include created_at if createdDate is defined
      },
    ]);
  }
  try {
    const embedUrl = `https://dev.thesearchbubble.com/embedProductSingle`;
    // const embedUrl = `http://localhost:3001/embedProductSingle`;

   axios.post(embedUrl, {
    user: usernames,
    id:randomNumber
  });
    // return res.data.embedding;
  } catch (error) { }
}



export async function addProductBulk(
  username: any,
  user_id: any,
  name: any,
  url: any,
  image: any,
  tags: any,
  category: any,
  platform: any,
  price: any
) {
  // Check for duplicate product (same user_id, category, and url)
  const { data: existingProduct, error: duplicateError } = await supabase
    .from("product_bank")
    .select("*")
    .eq("user_id", user_id)
    .eq("category", category)
    .eq("url", url);

  if (duplicateError) {
    console.error("Error checking for duplicates:", duplicateError.message);
    return;
  }

  if (existingProduct && existingProduct.length > 0) {
    // Product already exists
    return { message: "Product already exists" };
  }

  // If no duplicate found, proceed with uploading the product
  const post_id = generateRandom16DigitNumber();
  const imageUrl = await handleImageUpload(image, username, category, post_id);
    //combined_text

  // Insert the product into the database
  const { data: store, error: storeError } = await supabase
    .from("product_bank")
    .insert([
      {
        name,
        url,
        image: imageUrl,
        user_id,
        product_id: post_id,
        category,
        tagg: tags,
        platform:platform || 'MISC',
        price,
      },
    ]);

  if (storeError) {
    console.error("Error storing product:", storeError.message);
  }
}

// Function to generate a random 16-digit number for the post_id
function generateRandom16DigitNumber() {
  return (
    Math.floor(Math.random() * (9999999999999999 - 1000000000000000 + 1)) +
    1000000000000000
  );
}

// Function to handle image upload and return the image URL
async function handleImageUpload(
  image: any,
  username: any,
  category: any,
  post_id: any
) {
  // Default dummy image URL in case image is empty or null
  const dummyImageUrl =
    "https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/dummy_image/ProductBank/download.jpeg";
  if (!image || image === "") return dummyImageUrl; // Return dummy image if no image is provided

  try {
    const response = await fetch(image);
    const imageBlob = await response.blob();
    const mediaType = imageBlob.type.split("/")[0];
    const mediaExt = imageBlob.type.split("/")[1];
    const categoryUpload = category.split(" ")[0];

    const { data, error } = await supabase.storage
      .from("product_bank")
      .upload(
        `${username}/${categoryUpload}/${mediaType}${post_id}`,
        imageBlob,
        { contentType: `${imageBlob.type}` }
      );

    if (error) {
      console.error("Error uploading image:", error.message);
      return dummyImageUrl; // If upload fails, return the dummy image URL
    }

    // Fetch the public URL of the uploaded image
    const publicUrlData = await supabase.storage
      .from("product_bank")
      .getPublicUrl(`${username}/${categoryUpload}/${mediaType}${post_id}`);
    return publicUrlData.data.publicUrl; // Return the uploaded image's public URL
  } catch (err:any) {
    console.error("Error fetching image:", err.message);
    return dummyImageUrl; // Return the dummy image if any error occurs
  }
}
