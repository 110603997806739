import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { useState } from "react";

const RouteList = (props: any) => {
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
  };
  function handleClick(text: any, index: any = 2) {
    props.handleClick(text);
  }

  const drawer = (
    <div className="drawer-menu">
      <List>
        {[
          "Company Analytics",
          "Creators",
          "Analytics",
          "Account",
          "Content",
          "Live View Search",
          "Broken Links",
          "Product Bank",
          "Custom Categories",
          "Manage Links",
          "Controls",
          "Admin",
          "Global View Controls",
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              onClick={(e: any) => {
                handleClick(text, index);
                e.target.focus();
                handleListItemClick(e, index);
              }}
              id="listButton"
              className={`listButton${index}`}
              selected={selectedIndex === index}
            >
              {text}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
  return <div className="drawer">{drawer}</div>;
};

export default RouteList;
