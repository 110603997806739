import { Alert } from "antd";
import React, { useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { HiCheck, HiPencil } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { updateUsername } from "../UserDatabase";

const Username = (props: any) => {
  const navigate = useNavigate();

  const { sessionUser } = props;
  const [editUsername, setEditUsername] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [username, setUsername] = useState(props.username);

  function handleUpdateValue(e: any, option: any) {
    if (option === "username") {
      setUsername(e.target.value.toLowerCase());
    }
  }
  async function doneEdit() {
    // if (option === "username") {
    if (/^[a-z0-9_]+$/.test(username) && username.length > 3) {
      const resUsername = await updateUsername(username, sessionUser?.id);
      if (!resUsername) {
        setUsernameError("This username already exists");
        return;
      }
      setUsernameError("");
      navigate(`/dashboard/${username}`);
      setEditUsername(false);
    } else {
      setUsernameError(
        "Please enter a username consisting of letters, digits, and underscores. Minimum 6 characters."
      );
    }
    // }
  }
  return (
    <div className="account-grid-item">
      <div>
        <h3 className="account-grid-item-key">
          Username{" "}
          <BsInfoCircle className="info-tooltip" data-tooltip-id="username" />
        </h3>
        <h3 className="account-grid-item-value" id="name">
          {editUsername ? (
            <>
              <input
                type="text"
                name="username"
                required
                value={username}
                onChange={(e: any) => handleUpdateValue(e, "username")}
                autoComplete="do-not-autofill"
              />
              <button
                className="account-done-btn-name"
                onClick={() => {
                  doneEdit();
                }}
              >
                <HiCheck />
              </button>
            </>
          ) : (
            <div className="no-cursor">
              {username}
              <button
                className="account-edit-btn-name name-edit"
                onClick={() => {
                  // setEditUsername(true);
                }}
              >
                {/* <HiPencil /> */}
              </button>
            </div>
          )}
        </h3>
        {usernameError && <Alert type="warning" message={usernameError} />}
      </div>
    </div>
  );
};

export default Username;
