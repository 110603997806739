import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";
import { StaticVariables } from "../../../StaticVariables";

export function useLoadProductBankContent(
  user_id: string,
  username: any,
  currentPage: any,
  postsPerPage: any,
  totalPages: any,
  query: any
) {
  const fetchProductBankPosts = async () => {
    const start = (currentPage - 1) * postsPerPage;
    const end = start + postsPerPage - 1;
    let queryArray = query
      ? query
          .split(" ") // Split filter string into words
          .filter(
            (word: string) =>
              !StaticVariables().connectingWords.includes(word.toLowerCase())
          ) // Remove connecting words
          .map((elem: string) => `%${elem}%`)
      : [];
    // get_productbankpostsssdashh"
    const { data, error } = await supabase.rpc("get_productbankpostdashboard", {
      query_string: queryArray,
      userid: user_id,

      start_number: start,
      end_number: end,
    });

    if (error) {
      throw new Error(error.message);
    }
    return data;
  };

  return useInfiniteQuery(
    [
      "paginatedProductBankCount",
      // "ProductBankPosts",
       currentPage, query],
    fetchProductBankPosts,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: Boolean(totalPages),
    }
  );
}
