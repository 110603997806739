import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";
import axios from "axios";
import { UpdateCombinedText } from "../../../Utils/HelperFunction/HelperFunction";

// Hook for adding or removing tags
export default function useUpdateProductTags() {
  const queryClient = useQueryClient();

  
  const submitTag = async ({
    tagString,
    post_id,
    usernames,
    action,
    tag,
    user_id,
  }: {
    currentPage: any;
    activeKey: string;
    tagString: string;
    post_id: string;
    pageIndex: number;
    index: number;
    filter: string | null;
    usernames: any;
    action: any;
    tag: any;
    user_id: any;
  }) => {
    //combined_text

    const { error } = await supabase
      .from(`${usernames}`)
      .update({ tagg: tagString })
      .eq("post_id", `${post_id}`);

    if (action === "submit") {
      const { data } = await supabase
        .from("product_bank")
        .select("*")
        .eq("product_id", `${post_id}`)
        .eq("user_id", user_id)
        .limit(1);

      const { error } = await supabase
        .from("product_bank")
        .update({ tagg: tagString, updated_at: new Date() })
        .eq("product_id", `${post_id}`)
        .eq("user_id", user_id);
      // const embedUrl = `https://dev.thesearchbubble.com/productbankembed`;
      // // const embedUrl = `http://localhost:3001/productbankembed`;

      // const resThumbnail =axios.post(
      //   embedUrl,
      //   { id: user_id, user: usernames },
      //   {
      //     headers: {
      //       "content-type": "application/json",
      //     },
      //   }
      // );

      UpdateCombinedText(usernames, user_id, post_id);
    } else if (action === "remove") {
      const { data } = await supabase
        .from("product_bank")
        .select("*")
        .eq("product_id", `${post_id}`)
        .eq("user_id", user_id)
        .limit(1);
      const { error } = await supabase
        .from("product_bank")
        .update({ tagg: tagString, updated_at: new Date() })
        .eq("product_id", `${post_id}`)
        .eq("user_id", user_id);
      // const embedUrl = `https://dev.thesearchbubble.com/productbankembed`;
      // // const embedUrl = `http://localhost:3001/productbankembed`;

      // const resThumbnail = axios.post(
      //   embedUrl,
      //   { id: user_id, user: usernames },
      //   {
      //     headers: {
      //       "content-type": "application/json",
      //     },
      //   }
      // );

      UpdateCombinedText(usernames, user_id, post_id);
    }

    // if (error) {
    //   throw new Error(error.message);
    // } else {
    return true;
    // }
  };

  return useMutation(submitTag, {
    onMutate: async ({
      tagString,
      post_id,
      currentPage,
      usernames,
      pageIndex,
      index,
      activeKey,
      filter,
    }) => {
      await queryClient.cancelQueries("ProductBankPosts");

      // Optimistically update the local data
      const previousData = queryClient.getQueryData(["ProductBankPosts"]);

      queryClient.setQueryData(["ProductBankPosts"], (oldData: any) => {
        if (oldData) {
          // Find and update the specific post with new permalink
          const newData = oldData.map((page: any) =>
            page.map((post: any) =>
              console.log("fd", post)
              // post.table_id === post_id ? { ...post, table_tags: tagString } : post
            )
          );
          return newData;
        }
        return oldData;
      });

      return { previousData }; // Return context for rollback
    },
    onError: (error, variables, context) => {
      // Rollback on error by restoring the previous data
      queryClient.setQueryData(["ProductBankPosts"], context?.previousData);
    },
    onSettled: () => {
      // Invalidate and refetch the query to update the data
      queryClient.invalidateQueries("ProductBankPosts");
    },
  });
}
