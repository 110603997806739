import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";
import { fetchIncludedUsername } from "../../../SupabaseServices/UserDatabase";
import { getEmbedding } from "../../../SupabaseServices/ContentDatabase";

/**
 * Load content
 */

export function useLoadContent(
  currentPage: any,
  index: string,
  postsPerPage: number,
  totalPages: any,
  filter: string | null,
  activeCreator: any[],
  usernames: any,
  userIds: any[]
) {
  async function getPaginatedFeed({ pageParam = 1 }) {
    console.log("current", currentPage);
    let user_ids: any = [];
    let user_names: any = [];
    const selectedUser = localStorage.getItem("selectedUser");
    const currentUser = selectedUser ? JSON.parse(selectedUser) : null;
console.log("first", currentUser)
    const { data: userIds } = await supabase
    .from("user")
    .select("*")
    .eq("username", currentUser.currentUser);



    // const { data: userIds } = await supabase
    //   .from("user")
    //   .select("*")
    //   .eq("global_view", true);



    if (userIds) {
      const users: any = [];
      userIds.map((data: any) => {
        user_ids.push(data.user_id);
        user_names.push(data.username);
      });
    }

    console.log("first...", userIds, user_ids, user_names)
    // Unused variables that's why commented out below starts here
    // let selectedCreators: any = [];
    // const { data: users } = await supabase
    //   .from("user")
    //   .select("*")
    //   .eq("global_view", true);

    // if (users) {
    //   users.map((user: any) => {
    //     selectedCreators.push(user.user_id);
    //   });
    // }
// Unused variables that's why commented out below ends here


    // if (activeCreator !== undefined) {
    //   selectedCreators = activeCreator;
    // }
    const start = (currentPage - 1) * postsPerPage;
    const end = start + postsPerPage - 1;
    console.log("new", start, end);
    // const start = ((pageParam || 1) - 1) * postsPerPage;
    // const end = start + postsPerPage - 1;
    let data,
      error = null;
    if (filter) {
      filter = filter.replace(/[-_\s]/g, " ");

  

      let filterArray = filter
        .trim()
        .split(" ")
        .filter((word: any) => word.trim() !== "")
        .map((word) => word.trim());

      // Join the words with |
      let orJoinedFilter = filterArray.join(" | ");
      console.log("1", filter);
      let andfilterArray = filter
        .trim()
        .split(" ")
        .filter((word: any) => word.trim() !== "")
        .map((word: any) => word.trim());

      console.log("2", andfilterArray);
      let andJoinedFilter = andfilterArray.join(" & ");

      if (index === "Insta Highlights") {
        const { data: dataFilter, error: errorFilter } = await supabase.rpc(
          "get_dashboardcontentttthighlightsss",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            query_str: orJoinedFilter,
            user_name: user_names[0],
            index_type: "active",
            platform_type: ["instagram"],
            post_type: ["HIGHLIGHTS"],
            exact_str: andJoinedFilter,
          }
        );
        data = dataFilter;
        error = errorFilter;
      } else if (index === "Insta Story") {
        const { data: dataFilter, error: errorFilter } = await supabase.rpc(
          "get_dashboardcontenttttpostssts",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            query_str: orJoinedFilter,
            user_name: user_names[0],
            index_type: "active",
            platform_type: ["instagram"],
            post_type: ["STORY"],
          }
        );
        data = dataFilter;
        error = errorFilter;
      } else if (index === "Youtube") {
        const { data: dataFilter, error: errorFilter } = await supabase.rpc(
          "get_dashboardcontenttttpostssts",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            query_str: orJoinedFilter,
            user_name: user_names[0],
            index_type: "active",
            platform_type: ["youtube"],
            post_type: ["VIDEO"],
          }
        );
        data = dataFilter;
        error = errorFilter;
      } else if (index === "Tiktok") {
        const { data: dataFilter, error: errorFilter } = await supabase.rpc(
          "get_dashboardcontenttttpostssts",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            query_str: orJoinedFilter,
            user_name: user_names[0],
            index_type: "active",
            platform_type: ["tiktok"],
            post_type: ["TIKTOK"],
          }
        );

        data = dataFilter;
        error = errorFilter;
      } else if (index === "Podcast") {
        const { data: dataFilter, error: errorFilter } = await supabase.rpc(
          "get_dashboardcontenttttpostssts",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            query_str: orJoinedFilter,
            user_name: user_names[0],
            index_type: "active",
            platform_type: ["podcast"],
            post_type: ["PODCAST"],
          }
        );
        data = dataFilter;
        error = errorFilter;
      } else if (index === "Pinterest") {
        const { data: dataFilter, error: errorFilter } = await supabase.rpc(
          "get_dashboardcontenttttpostssts",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            query_str: orJoinedFilter,
            user_name: user_names[0],
            index_type: "active",
            platform_type: ["pinterest"],
            post_type: ["PIN"],
          }
        );
        data = dataFilter;
        error = errorFilter;
      } else if (index === "Newsletter") {
        const { data: dataFilter, error: errorFilter } = await supabase.rpc(
          "get_dashboardcontenttttpostssts",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            query_str: orJoinedFilter,
            user_name: user_names[0],
            index_type: "active",
            platform_type: ["newsletter"],
            post_type: ["MAIL"],
          }
        );
        data = dataFilter;
        error = errorFilter;
      } else {
        const { data: dataFilter, error: errorFilter } = await supabase.rpc(
          "get_dashboardcontenttttpostssss",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            query_str: orJoinedFilter,
            user_name: user_names[0],
            index_type: index,
            platform_type: ["instagram"],
            post_type: ["FEED", "REELS"],
            exact_str: andJoinedFilter,
          }
        );
        data = dataFilter;
        error = errorFilter;
      }
    } else {
      let dataNoFilter: any = [];
      let errorNoFilter;
      // const { data: dataNoFilter, error: errorNoFilter }: any = await supabase
      //   .from("content_new")
      //   .select("*")
      //   .in("user_id", selectedCreators)
      //   .ilike("index", `%${index}%`)
      //   .range(start, end)
      //   .order(index !== "active,ad" ? "post_date" : "global_ads_order", {
      //     ascending: index !== "active,ad" ? false : true,
      //   });

      // data = dataNoFilter;
      // error = errorNoFilter;

      console.log("indd", user_ids, user_names);
      if (index === "Insta Highlights") {
        const { data, error } = await supabase.rpc(
          "get_dashboardcontentnofilterrpostssss",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            user_name: user_names[0],
            index_type: "active",
            platform_type: ["instagram"],
            post_type: ["HIGHLIGHTS"],
          }
        );
        dataNoFilter = data;
        errorNoFilter = error;
      } else if (index === "Insta Story") {
        const { data, error } = await supabase.rpc(
          "get_dashboardcontentnofilterrpostssss",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            user_name: user_names[0],
            index_type: "active",
            platform_type: ["instagram"],
            post_type: ["STORY"],
          }
        );
        dataNoFilter = data;
        errorNoFilter = error;
      } else if (index === "Youtube") {
        const { data, error } = await supabase.rpc(
          "get_dashboardcontentnofilterrpostssss",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            user_name: user_names[0],
            index_type: "active",
            platform_type: ["youtube"],
            post_type: ["VIDEO"],
          }
        );
        dataNoFilter = data;
        errorNoFilter = error;
      } else if (index === "Tiktok") {
        const { data, error } = await supabase.rpc(
          "get_dashboardcontentnofilterrpostssss",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            user_name: user_names[0],
            index_type: "active",
            platform_type: ["tiktok"],
            post_type: ["TIKTOK"],
          }
        );
        console.log("tik", data);
        dataNoFilter = data;
        errorNoFilter = error;
      } else if (index === "Podcast") {
        const { data, error } = await supabase.rpc(
          "get_dashboardcontentnofilterrpostssss",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            user_name: user_names[0],
            index_type: "active",
            platform_type: ["podcast"],
            post_type: ["PODCAST"],
          }
        );
        dataNoFilter = data;
        errorNoFilter = error;
      } else if (index === "Pinterest") {
        const { data, error } = await supabase.rpc(
          "get_dashboardcontentnofilterrpostssss",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            user_name: user_names[0],
            index_type: "active",
            platform_type: ["pinterest"],
            post_type: ["PIN"],
          }
        );
        dataNoFilter = data;
        errorNoFilter = error;
      } else if (index === "Newsletter") {
        const { data, error } = await supabase.rpc(
          "get_dashboardcontentnofilterrpostssss",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            user_name: user_names[0],
            index_type: "active",
            platform_type: ["newsletter"],
            post_type: ["MAIL"],
          }
        );
        dataNoFilter = data;
        errorNoFilter = error;
      } else {
        const { data, error } = await supabase.rpc(
          "get_dashboardcontentnofilterrpostssss",
          {
            userid: user_ids[0],
            start_number: start,
            end_num: end,
            user_name: user_names[0],
            index_type: index,
            platform_type: ["instagram"],
            post_type: ["FEED", "REELS"],
          }
        );
        dataNoFilter = data;
        errorNoFilter = error;
      }

      const idsToFetch = dataNoFilter.map((row: any) => row.post_id);
      // Fetch "tags_api" column from "content_new" for the specific IDs
      const { data: tagsApiData, error: tagsApiError }: any = await supabase
        .from("content_new")
        .select("post_id,tags_api")
        .in("post_id", idsToFetch)
        .eq("user_id", user_ids[0]);

      // Handle errors if needed
      if (tagsApiError) {
        // Handle the error
      }

      // Merge "tags_api" into each row of the original data
      const mergedData = dataNoFilter.map((row: any) => {
        const matchingTagApi = tagsApiData.find(
          (tagRow: any) => tagRow.post_id === row.post_id
        );

        // Assuming "tags_api" is an array in your database
        const tagsApi = matchingTagApi ? matchingTagApi.tags_api : "";

        // Merge the "tags_api" into the original row
        return {
          ...row,
          tags_api: tagsApi,
        };
      });
      data = mergedData;
      error = errorNoFilter;
    }
    if (data && data.length >= 0 && Array.isArray(data)) {
      return data;
    } else if (error) {
      throw new Error(error.message);
    } else {
      throw new Error("Failed to fetch posts");
    }
  }

  return useInfiniteQuery(
    ["paginatedPosts", currentPage, index, filter],
    getPaginatedFeed,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: Boolean(userIds) && Boolean(totalPages),
    }
  );
}
