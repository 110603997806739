import { Button } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { getUserData } from "../../../../SupabaseServices/UserDatabase";
import Papa from "papaparse";
import { addProductBulk } from "../../../../SupabaseServices/ProductBankDatabase";

const ProductBankUpload = (props: any) => {
  const { selectedUser } = props;
  console.log("user", selectedUser);
  const [tags, setTags] = useState("");
  const [platform, setPlatform] = useState("");
  const [selectedFiles, setSelectedFiles] = useState<any>([]);

  const handleFolderChange = async (event: any) => {
    const files = Array.from(event.target.files);
    const filteredFiles = files.filter((file: any) => file.size > 0);

    setSelectedFiles(filteredFiles);
  };
  const dummyImage =
    "https://nneqehyphaavozzymtwp.supabase.co/storage/v1/object/public/dummy_image/ProductBank/download.jpeg";
  const uploadFolder = async () => {
    const uploadData: any = [];
    const csvFiles: any = [];
    selectedFiles.map((file: any) => {
      if (file.name.toLowerCase().endsWith(".csv")) {
        csvFiles.push(file);
      } else {
        uploadData.push(file);
      }
    });
    console.log("diff", csvFiles, uploadData);
    let progressCount = 1;

    const userData: any = await getUserData(selectedUser);

    let urls: any = [];
    console.log("userdtaa", userData);

    if (csvFiles) {
      console.log("cc", csvFiles[0]);
      try {
        const result: any = await new Promise((resolve, reject) => {
          Papa.parse(csvFiles[0], {
            header: true, // if your CSV file has a header row
            skipEmptyLines: true,
            complete: async (result) => {
              console.log("result", result);
              console.log("total", result.data, result.data.length);
              const reversedData = result.data.reverse();

              for (let [i, r] of reversedData.entries()) {
                if (typeof r === "object" && r !== null) {
                  const categoryKey = Object.keys(r).find(
                    (key) => key.trim().toLowerCase() === "category"
                  );
                  const imageUrlsKey = Object.keys(r).find(
                    (key) => key.trim().toLowerCase() === "image urls"
                  );
                  const indexKey = Object.keys(r).find(
                    (key) => key.trim().toLowerCase() === "index"
                  );
                  const linksKey = Object.keys(r).find(
                    (key) => key.trim().toLowerCase() === "links"
                  );
                  const productNamesKey = Object.keys(r).find(
                    (key) => key.trim().toLowerCase() === "product names"
                  );
                  const priceKey = Object.keys(r).find(
                    (key) => key.trim().toLowerCase() === "price"
                  );

                  const categoryValue = categoryKey
                    ? r[categoryKey as keyof typeof r]
                    : csvFiles[0].name.replace(".csv", "");
                  let imageUrlsValue: any = imageUrlsKey
                    ? r[imageUrlsKey as keyof typeof r]
                    : "";
                  const indexValue = indexKey
                    ? r[indexKey as keyof typeof r]
                    : "";
                  const linksValue = linksKey
                    ? r[linksKey as keyof typeof r]
                    : "";
                  const productNamesValue = productNamesKey
                    ? r[productNamesKey as keyof typeof r]
                    : "";
                  const priceValue = priceKey
                    ? r[priceKey as keyof typeof r]
                    : "";

                  // Your existing code for totalTags
                  const totalTags: any = [
                    ...new Set([
                      ...tags.split(","),
                      csvFiles[0].name.replace(".csv", ""),
                      categoryValue,
                      platform,
                    ]),
                  ]
                    .filter((tag) => tag && tag.trim() !== "") // Remove empty strings or spaces
                    .map((tag) => `|${tag.trim()}|`) // Enclose each word with | |
                    .join(","); // Join the words with a comma

                  // Logging values
                  console.log(i, r);
                  console.log("Category:", categoryValue);
                  console.log("Image Urls:", imageUrlsValue);
                  console.log("Index:", indexValue);
                  console.log("Links:", linksValue);
                  console.log("Product Names:", productNamesValue);
                  console.log("Total Tags:", totalTags);
                  //    let image="https://www.hymebeauty.com/en-us/products/pnkre-eyebrow-shaper?utm_source=shopmy"
                  //     const response = await fetch(image);

                  //     const imageBlob = await response.blob();
                  //     console.log("fdf",response)

                  await addProductBulk(
                    userData.username,
                    userData.user_id,
                    productNamesValue,
                    linksValue,
                    imageUrlsValue ? imageUrlsValue : dummyImage,
                    totalTags,
                    categoryValue,
                    platform?platform:"MISC",
                    priceValue
                  );
                  console.log("product added", i);
                } else {
                  console.error("Unexpected type for r:", r);
                }
              }
              try {
                const embedUrl = `https://dev.thesearchbubble.com/shortName`;
                // const embedUrl = `http://localhost:3001/embedProduct`;
        
                const res = axios.post(
                  embedUrl,
                  {
                    user: selectedUser,
                  },
                  {
                    // headers: {
                    //   "content-type": "application/json",
                    // },
                  }
                );
                // return res.data.embedding;
              } catch (error) {}

              try {
                const embedUrl = `https://dev.thesearchbubble.com/embedProduct`;
                // const embedUrl = `http://localhost:3001/embedProduct`;
        
                const res = axios.post(
                  embedUrl,
                  {
                    user: selectedUser,
                  },
                  {
                    // headers: {
                    //   "content-type": "application/json",
                    // },
                  }
                );
                // return res.data.embedding;
              } catch (error) {}
            },
            error: (error) => {
              console.error("Error parsing CSV file:", error);
            },
          });
        });
      } catch (e) {
        console.log("error", e);
      }
    }
  };
  return (
    <div className="content-wrapper">
      <span className="content-block">
        <input
          required
          className="caption-input input-value"
          type="text"
          placeholder="enter tags"
          onChange={(e: any) => setTags(e.target.value)}
          value={tags}
        />
        <input
          required
          className="caption-input input-value"
          type="text"
          placeholder="enter platform"
          onChange={(e: any) => setPlatform(e.target.value)}
          value={platform}
        />
      </span>

      <h6>Upload folder</h6>
      <input type="file" multiple onChange={handleFolderChange} />
      <Button type="primary" className="primary-color" onClick={uploadFolder}>
        Upload
      </Button>
    </div>
  );
};

export default ProductBankUpload;
