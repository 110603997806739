import { useMutation, useQueryClient } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";
import { UpdateCombinedText } from "../../../Utils/HelperFunction/HelperFunction";

// Hook for adding or removing tags
export default function useUpdateTags() {
  const queryClient = useQueryClient();

  const submitTag = async ({
    tagString,
    post_id,
    usernames,
    action,
    tag,
    user_id
  }: {

    currentPage: any;
    activeKey: string;
    tagString: string;
    post_id: string;
    pageIndex: number;
    index: number;
    filter: string | null;
    usernames: any;
    action: any;
    tag: any;
    user_id:any;
  }) => {
    console.log("ddfdfdd", tagString, post_id, usernames[0]);
    const { error } = await supabase
      .from(`${usernames[0]}`)
      .update({ tagg: tagString })
      .eq("post_id", `${post_id}`);
 
      try {
     
        const { error: e1 } = await supabase
          .from("content_new")
          .update({
            tagg: tagString,
           
          })
          .eq("post_id", `${post_id}`).eq("user_id",user_id);
      } catch (e) {}

    
        await supabase
        .from('ads_content')
        .update({ tagg: tagString })
        .eq("post_id", `${post_id}`).eq("user_id",user_id);

        await supabase
        .from('broken_links_table')
        .update({ tagg: tagString })
        .eq("post_id", `${post_id}`).eq("user_id",user_id);
    
      UpdateCombinedText(usernames,user_id, post_id);
    

    if (error) {
      throw new Error(error.message);
    } else {
      return true;
    }
  };

  // Tag optimistic update
  //   return useMutation(submitTag, {
  //     // When mutate is called:
  //     onMutate: async ({ tagString, pageIndex, index, activeKey, filter }) => {
  //       await queryClient.cancelQueries(["paginatedPosts", activeKey, filter]);
  //       const previousData = queryClient.getQueryData([
  //         "paginatedPosts",
  //         activeKey,
  //         filter,
  //       ]);
  //       if (previousData) {
  //         // Deep clone the object, otherwise the objects inside will have the same reference in memory
  //         const newData = JSON.parse(JSON.stringify(previousData)) as {
  //           pageParams: any;
  //           pages: any[];
  //         };
  //         newData.pages[pageIndex][index].tagg = tagString;
  //         queryClient.setQueryData(
  //           ["paginatedPosts", activeKey, filter],
  //           newData
  //         );
  //       }
  //       return { previousData };
  //     },
  //     // If the mutation fails, use the context returned from onMutate to roll back
  //     onError: (_error, { activeKey, filter }, context) => {
  //       queryClient.setQueryData(
  //         ["paginatedPosts", activeKey, filter],
  //         context?.previousData
  //       );
  //     },
  //     // Always refetch after error or success:
  //     onSettled: (_data, _error, { pageIndex, activeKey, filter }) => {
  //       queryClient.invalidateQueries(["paginatedPosts", activeKey, filter], {
  //         refetchPage: (page, index) => {
  //           return index === pageIndex;
  //         },
  //       });
  //     },
  //   });
  // }

  return useMutation(submitTag, {
    onMutate: async ({
      tagString,
      post_id,
      currentPage,
      usernames,
      pageIndex,
      index,
      activeKey,
      filter,
    }) => {
      await queryClient.cancelQueries("paginatedPosts");

      // Optimistically update the local data
      const previousData = queryClient.getQueryData(["paginatedPosts"]);

      queryClient.setQueryData(["paginatedPosts"], (oldData: any) => {
        if (oldData) {
          // Find and update the specific post with new permalink
          const newData = oldData.map((page: any) =>
            page.map((post: any) =>
              post.post_id === post_id ? { ...post, tagg: tagString } : post
            )
          );
          return newData;
        }
        return oldData;
      });

      return { previousData }; // Return context for rollback
    },
    onError: (error, variables, context) => {
      // Rollback on error by restoring the previous data
      queryClient.setQueryData(["paginatedPosts"], context?.previousData);
    },
    onSettled: () => {
      // Invalidate and refetch the query to update the data
      queryClient.invalidateQueries("paginatedPosts");
    },
  });
}
