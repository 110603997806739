import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";
import { fetchIncludedUsername } from "../../../SupabaseServices/UserDatabase";
import { getEmbedding } from "../../../SupabaseServices/ContentDatabase";

/**
 * Load content
 */

export function useLoadEmbeddingPosts(
  currentPage: any,
  index: string,
  postsPerPage: number,
  totalPages: any,
  filter: string | null,
  activeCreator: any[],
  usernames: any,
  userIds: any[],
  postIds: any[],
  threshold: any
) {
  async function getPaginatedFeed({ pageParam = 1 }) {
    let user_ids: any = [];
    let user_names: any = [];
    // const { data: userIds } = await supabase
    //   .from("user")
    //   .select("*")
    //   .eq("global_view", true);

    const selectedUser = localStorage.getItem("selectedUser");
    const currentUser = selectedUser ? JSON.parse(selectedUser) : null;

    const { data: userIds } = await supabase
      .from("user")
      .select("*")
      .eq("username", currentUser.currentUser);

    if (userIds) {
      const users: any = [];
      userIds.map((data: any) => {
        user_ids.push(data.user_id);
        user_names.push(data.username);
      });
    }

    // Unused variables that's why commented out below starts here
    // let selectedCreators: any = [];
    // const { data: users } = await supabase
    //   .from("user")
    //   .select("*")
    //   .eq("global_view", true);

    // if (users) {
    //   users.map((user: any) => {
    //     selectedCreators.push(user.user_id);
    //   });
    // }
    // Unused variables that's why commented out below ends here



    // if (activeCreator !== undefined) {
    //   selectedCreators = activeCreator;
    // }
    const start = (currentPage - 1) * postsPerPage;
    const end = start + postsPerPage - 1;
    console.log("new", start, end);
    // const start = ((pageParam || 1) - 1) * postsPerPage;
    // const end = start + postsPerPage - 1;
    let data,
      error = null;
    if (userIds && userIds.length === 1) {
      if (filter) {
        const elementsCount = 5000;

        const connectingWords = [
          "and",
          "or",
          "but",
          "if",
          "because",
          "although",
          "since",
          "while",
          "when",
          "after",
          "before",
          "as",
          "that",
          "which",
          "who",
          "whom",
          "whose",
          "where",
          "what",
          "how",
          "the",
          "an",
          "a",
          "in",
          "on",
          "at",
          "with",
          "by",
          "for",
          "of",
          "to",
          "from",
          "through",
          "into",
          "during",
          "within",
          "against",
          "between",
          "among",
          "under",
          "above",
          "below",
          "over",
          "throughout",
          "around",
          "beside",
          "beneath",
          "up",
          "down",
          "off",
          "on",
          "out",
          "over",
          "through",
          "together",
          "apart",
          "forward",
          "backward",
          "away",
          "back",
          "with",
          "without",
          "about",
          "for",
          "against",
          "like",
          "unlike",
          "as",
          "with",
          "without",
          "are",
          "is",
          "do",
          "you",
          "am",
          "I",
        ];

        // Split the sentence into words
        const words = filter.split(" ");

        // Filter out connecting words
        const filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );

        // Join the filtered words back into a sentence
        const result = filteredWords.join(" ");
        const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");
        const embedding = await getEmbedding(pgQuery);

        let totalResult = [];

        const { data: searchResult, error: searchError } = await supabase.rpc(
          // "semantic_sssearch",
          // "semanticc_multipleadssearch",
          "semanticcc_embedpostidddddt",
          {
            query_string: pgQuery,
            query_embedding: embedding,
            // similarity_threshold: 0.3146,
            similarity_threshold: threshold,

            // match_count: elementsCount,
            start_number: start,
            end_num: end,
            userid: userIds[0].user_id,
            username: usernames[0],
            socials: [
              "instagram",
              "youtube",
              "tiktok",
              "podcast",
              "pinterest",
              "newsletter",
            ],
            idx: 0,
            show_ads: false,
            postids: postIds, // true if end + 1 is a multiple of 10
          }
        );

        data = searchResult;
        error = searchError;
      }

      if (data && data.length >= 0 && Array.isArray(data)) {
        return data;
      } else if (error) {
        //   throw new Error(error);
      } else {
        throw new Error("Failed to fetch posts");
      }
    }
  }

  return useInfiniteQuery(
    ["paginatedEmbedPosts", currentPage, index, filter],
    getPaginatedFeed,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: Boolean(totalPages),
    }
  );
}
