import { useInfiniteQuery, useQuery } from "react-query";
import { supabase } from "../../../Config/SupabaseClient";
import { fetchIncludedUsername } from "../../../SupabaseServices/UserDatabase";
import { getEmbedding } from "../../../SupabaseServices/ContentDatabase";

/**
 * Load content
 */

export function useLoadPosts(
  currentPage: any,
  index: string,
  postsPerPage: number,
  totalPages: any,
  filter: string | null,
  activeCreator: any[],
  usernames: any,
  userIds: any[],
  postIds: any[]
) {
  async function getPaginatedFeed({ pageParam = 1 }) {
    let user_ids: any = [];
    let user_names: any = [];
    // const { data: userIds } = await supabase
    //   .from("user")
    //   .select("*")
    //   .eq("global_view", true);

    const selectedUser = localStorage.getItem("selectedUser");
    const currentUser = selectedUser ? JSON.parse(selectedUser) : null;

    const { data: userIds } = await supabase
      .from("user")
      .select("*")
      .eq("username", currentUser.currentUser);

    if (userIds) {
      const users: any = [];
      userIds.map((data: any) => {
        user_ids.push(data.user_id);
        user_names.push(data.username);
      });
    }

    // Unused variables that's why commented out below starts here
    // let selectedCreators: any = [];
    // const { data: users } = await supabase
    //   .from("user")
    //   .select("*")
    //   .eq("global_view", true);

    // if (users) {
    //   users.map((user: any) => {
    //     selectedCreators.push(user.user_id);
    //   });
    // }
    // Unused variables that's why commented out below ends here

    // if (activeCreator !== undefined) {
    //   selectedCreators = activeCreator;
    // }
    const start = (currentPage - 1) * postsPerPage;
    const end = start + postsPerPage - 1;
    console.log("new", start, end);
    // const start = ((pageParam || 1) - 1) * postsPerPage;
    // const end = start + postsPerPage - 1;
    let data,
      error = null;
    if (userIds && userIds.length === 1) {
      if (filter) {
        const elementsCount = 5000;

        // const embedding = await getEmbedding(queryTerm);

        // await getEmbedding(queryTerm);
        const connectingWords = [
          "and",
          "or",
          "but",
          "if",
          "because",
          "although",
          "since",
          "while",
          "when",
          "after",
          "before",
          "as",
          "that",
          "which",
          "who",
          "whom",
          "whose",
          "where",
          "what",
          "how",
          "the",
          "an",
          "a",
          "in",
          "on",
          "at",
          "with",
          "by",
          "for",
          "of",
          "to",
          "from",
          "through",
          "into",
          "during",
          "within",
          "against",
          "between",
          "among",
          "under",
          "above",
          "below",
          "over",
          "throughout",
          "around",
          "beside",
          "beneath",
          "up",
          "down",
          "off",
          "on",
          "out",
          "over",
          "through",
          "together",
          "apart",
          "forward",
          "backward",
          "away",
          "back",
          "with",
          "without",
          "about",
          "for",
          "against",
          "like",
          "unlike",
          "as",
          "with",
          "without",
          "are",
          "is",
          "do",
          "you",
          "am",
          "I",
        ];

        // Split the sentence into words
        let words = filter.split(" ");
        let singleWords: any = [];
        if (words.length === 1) {
          // const suffixRegex = /(s|es|ves|ies)$/i;

          // if (suffixRegex.test(words[0])) {
          //   singleWords = [`${words[0]}`];

          //   words = [lemmatizer(words[0])];
          //   singleWords.push(`${lemmatizer(words[0])} `);
          // }else{
          singleWords = [`${words[0]}`];

          // }
          // singleWords.push(`${}`)
        }

        // Filter out connecting words
        const filteredWords = words.filter(
          (word: any) => !connectingWords.includes(word.toLowerCase())
        );
        if (words.length <= 2) {
          // Join the filtered words back into a sentence
          const result = filteredWords.join(" ");
          const pgQuery = result.toLowerCase().replace(/\s+/g, " & ");

          let totalResult = [];

          // if (avgWords <= 20) {
          //   if (!isNecCalledRef.current.current) {
          //     const extraIds = await getExtraNecIds(queryTerm, username);
          //     const { data: postData, error } = await supabase.rpc(
          //       "get_getnecfeed",
          //       {
          //         userid: user_id[0],
          //         user_name: username,
          //         extraids: extraIds,
          //       }
          //     );

          //     if (postData) {
          //       totalResult.unshift(...postData);

          //       isNecCalledRef.current.current = true;
          //     }
          //   }
          //   const { data: searchResult, error: searchError } =
          //     await supabase.rpc(
          //       // "semantic_sssearch",
          //       "semanticc_multipleadssearch",
          //       {
          //         query_string: pgQuery,
          //         query_embedding: embedding,
          //         similarity_threshold: 0.80,
          //         match_count: elementsCount,
          //         start_number: start,
          //         end_num: end,
          //         userid: user_id[0],
          //         username: username,
          //         socials: showSocials,
          //         idx: adsIndex || 0,
          //         show_ads: (end + 1) % 10 === 0 ?true:false, // true if end + 1 is a multiple of 10

          //       }
          //     );
          //   totalResult.push(...searchResult);
          //   // ... (other logic)
          //   data = [...totalResult];
          // } else {
          const { data: searchResult, error: searchError } = await supabase.rpc(
            // "semantic_sssearch",
            // "semanticc_multipleadssearch",
            // "semanticcc_exact",
            "semanticcc_ftsspostiddddt",
            {
              query_string: pgQuery,
              // query_embedding: embedding,
              // similarity_threshold: 0.80,
              // match_count: elementsCount,
              start_number: start,
              end_num: end,
              userid: userIds[0].user_id,
              username: usernames[0],
              socials: [
                "instagram",
                "youtube",
                "tiktok",
                "podcast",
                "pinterest",
                "newsletter",
              ],

              idx: 0,
              show_ads: false,
              postids: postIds, // true if end + 1 is a multiple of 10
              // single: singleWords[0] || null,
              //   plural: singleWords[1] || null,
            }
          );
          data = searchResult;
        } else {
          data = [];
        }
      } else {
        console.log("fd user", usernames, user_names);
        const { data: rpc, error: rpcError } = await supabase.rpc(
          // "get_generalffffeed",
          // "get_adsssssgeneralffffeed",

          "adssssgeneralfeeddt",
          {
            userid: userIds[0].user_id,
            start_number: start,
            end_num: end,
            filter: [
              "FEED",
              "REELS",
              "STORY",
              "HIGHLIGHTS",
              "PIN",
              "VIDEO",
              "PODCAST",
              "TIKTOK",
            ],
            query_str: "",
            user_name: user_names[0],
            socials: [
              "instagram",
              "youtube",
              "tiktok",
              "podcast",
              "pinterest",
              "newsletter",
            ],
            idx: 0,
            show_ads: false, // true if end + 1 is a multiple of 10

            // extra_ids: extraId,
          }
        );
        console.log("fdhd", rpcError, rpc);
        data = rpc;
        error = rpcError;
      }

      if (data && data.length >= 0 && Array.isArray(data)) {
        return data;
      } else if (error) {
        // throw new Error(error);
      } else {
        throw new Error("Failed to fetch posts");
      }
    }
  }

  return useInfiniteQuery(
    ["paginatedPosts", currentPage, index, filter],
    getPaginatedFeed,
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length < totalPages) {
          return pages.length + 1;
        } else {
          return undefined;
        }
      },
      enabled: Boolean(userIds) && Boolean(totalPages),
    }
  );
}
