import { Alert, Button } from "antd";
import React, { useState } from "react";
import { getUserData } from "../../../../SupabaseServices/UserDatabase";
import { supabase } from "../../../../Config/SupabaseClient";
import axios from "axios";

const StoryContent = (props: any) => {
  const { user } = props;
  // const [postId, setPostId] = useState("");
  const [shoplink, setShoplink] = useState("");
  const [tags, setTags] = useState("");

  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const handleUploadFiles = (files: any) => {
    const uploaded = [...uploadedFiles];
    files.some((file: any) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
      }
    });

    setUploadedFiles(uploaded);
  };
  const handleFileEvent = (e: any) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  async function uploadFiles(user: any) {
    alert("uploading stories, wait!")
    const userData: any = await getUserData(user);
    console.log("user", userData, uploadedFiles);
    uploadedFiles.map(async (file: any) => {
      const randomNumber =
        Math.floor(Math.random() * 90000000000000000000) + 10000000000000000000;
      console.log(file, randomNumber);
      let postId = randomNumber;
      const mediaType = file.type.split("/")[0];
      const mediaExt = file.type.split("/")[1];
      let tagsToProcess = tags.split(",");
      const modifiedArray = tagsToProcess.map(
        (word: any) => `|${word.trim()}|`
      );
      //   let postNumber = Math.floor(Math.random() * 10000 + 1);

      await supabase.storage
        .from("stories")
        .upload(`${user}/${mediaType}${postId}.${mediaExt}`, file, {
          contentType: `${file.type}`,
        });
      var { data: url }: any = await supabase.storage
        .from("stories")
        .getPublicUrl(`${user}/${mediaType}${postId}.${mediaExt}`);

      if (url !== "undefined") {
        var { data: storedPost, error: storedError } = await supabase
          .from("content_instagram")
          .select("*")
          .eq("post_id", postId)
          .eq("user_id", userData.user_id);
        if (storedPost && storedPost.length === 0) {
          var { data, error }: any = await supabase
            .from("content_instagram")
            .insert([
              {
                post_id: postId,
                user_id: userData.user_id,
                provider_id: userData.id,
                caption: " ",
                media_url: url.publicUrl,
                type: "STORY",
                storage_url: url.publicUrl,
                permalink: "",
                post_date:
                  new Date().toISOString().replace('T', ' ').slice(0, 23) + "+00",
                  shoplink:shoplink?shoplink: "",

              },
            ])
            .select("*");          await supabase.from("content_new").insert([
            {
              post_id: postId,
              user_id: userData.user_id,
              caption: " ",
              media_url: url.publicUrl,
              type: "STORY",
              platform: "instagram",
              post_date: new Date().toISOString().replace('T', ' ').slice(0, 23) + "+00",
              raw_content_id: data[0].id,
              permalink: "",
              shoplink:shoplink?shoplink: "",
              tagg:modifiedArray.toString(),



            },
          ]);
          // const thumbnailUrl = `https://dev.thesearchbubble.com/fetch/thumbnail`;
          // const thumbnailUrl = `http://localhost:3001/fetch/thumbnail`;
          const thumbnailUrl = `https://dev.thesearchbubble.com/api/thumbnail`;

           axios.post(
            thumbnailUrl,
            { id: userData.user_id, user: user, from: "content" },
            {
              headers: {
                "content-type": "application/json",
              },
            }
          );
          // console.log("res", resThumbnail.data);
          alert("uploading story")
            await supabase
            .from("user")
            .update({ show_instagram: true,is_instagram_on:true })
            .eq("user_id", userData.user_id);
          setShoplink("")
          setTags("")
          const files: any = document.getElementById("fileUpload");

          files.value = "";
          // props.setIsModalOpen(false);
        } else {
          <Alert
            type="error"
            message="Error in uploading Stories - duplicate post"
            showIcon
            className="error-alert"
          />;
        }
      } else {
        <Alert
          type="error"
          message="Error in uploading Stories"
          showIcon
          className="error-alert"
        />;
      }
    });
    // const file = uploadedFiles[0];
    // const mediaType = file.type.split("/")[0];
    // const mediaExt = file.type.split("/")[1];
    // //   let postNumber = Math.floor(Math.random() * 10000 + 1);

    // await supabase.storage
    //   .from("stories")
    //   .upload(`${user}/${mediaType}${postId}.${mediaExt}`, file, {
    //     contentType: `${file.type}`,
    //   });
    // var { data: url }: any = await supabase.storage
    //   .from("stories")
    //   .getPublicUrl(`${user}/${mediaType}${postId}.${mediaExt}`);

    // if (url !== "undefined") {
    //   var { data, error }: any = await supabase
    //     .from("content_instagram")
    //     .insert([
    //       {
    //         post_id: postId,
    //         user_id: userData.user_id,
    //         provider_id: userData.id,
    //         caption: " ",
    //         media_url: url.publicUrl,
    //         type: "STORY",
    //         storage_url: url.publicUrl,
    //         permalink: permalink ? permalink : "",
    //       },
    //     ])
    //     .select("*");

    //   await supabase.from("content_new").insert([
    //     {
    //       post_id: postId,
    //       user_id: userData.user_id,
    //       caption: " ",
    //       media_url: url.publicUrl,
    //       type: "STORY",
    //       platform: "instagram",
    //       post_date: new Date().toLocaleString(),
    //       raw_content_id: data[0].id,
    //       permalink: permalink ? permalink : "",
    //     },
    //   ]);
    //   setPostId("");
    //   setPermalink("");
    //   setUploadedFiles([]);
    //   const files: any = document.getElementById("fileUpload");

    //   files.value = "";
    //   props.setIsModalOpen(false);
    // } else {
    //   <Alert
    //     type="error"
    //     message="Error in uploading Stories"
    //     showIcon
    //     className="error-alert"
    //   />;
    // }
    // });
  }
  return (
    <div className="content-wrapper">
      <h5>Upload Story </h5>
      
      {/* <span className="content-block">
        <p className="input-title">Post Id:-</p>
        <input
          required
          className="post-id-input input-value"
          type="text"
          placeholder="enter story post id"
          onChange={(e: any) => setPostId(e.target.value)}
          value={postId}
        />
      </span> */}
      {/* <br />
      <span className="content-block">
        <p className="input-title">Permalink (if any):-</p>
        <input
          className="permalink-input input-value"
          type="text"
          placeholder="enter permalink"
          onChange={(e: any) => setPermalink(e.target.value)}
          value={permalink}
        />
      </span>
      <br /> */}
       <span className="content-block">
          <p className="input-title">Shoplink (if any):-</p>
          <input
            className="permalink-input input-value"
            type="text"
            placeholder="enter shoplink"
            value={shoplink}
            onChange={(e: any) => setShoplink(e.target.value)}
          />
        </span>
        <br/>
      <span className="content-block">
        <p className="input-title">Tags:- (Put all tags with comma at once)</p>
        <input
          required
          className="permalink-input input-value"
          type="text"
          placeholder="enter tags"
          onChange={(e: any) => setTags(e.target.value)}
          value={tags}
        />
      </span>
      <input
        id="fileUpload"
        type="file"
        accept="application/pdf, image/png, image/jpeg, video/mp4, image/webp"
        onChange={handleFileEvent}
        multiple
      />

      <Button
        type="primary"
        className="upload-btn primary-color"
        onClick={() => uploadFiles(user)}
        // placeholder={uploadedFiles}
      >
        {" "}
        Upload Files{" "}
      </Button>
    </div>
  );
};

export default StoryContent;
