import { Button } from "antd";
import React, { useState } from "react";
import { supabase } from "../../../../Config/SupabaseClient";
import axios from "axios";
import { getUserData } from "../../../../SupabaseServices/UserDatabase";

const SingleEmbedding = (props: any) => {
  const { username } = props;
  const [postId, setPostId] = useState("");
  async function generateEmbedding() {
    const userData: any = await getUserData(username);

    if (postId) {
      const post_ids = postId.trim().split(",");
      const { error } = await supabase
        .from(`${username}`)
        .delete()
        .in("post_id", post_ids);
      setTimeout(async () => {
        const embedUrl = `https://dev.thesearchbubble.com/insertEmbed`;
        // const embedUrl = `http://localhost:3001/insertEmbed`;

        const resThumbnail = await axios.post(
          embedUrl,
          { id: userData.user_id, user: userData.username, from: "content" },
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );
        console.log("res", resThumbnail.data);
        if (resThumbnail.data === "done") {
          alert("Generating selective embedding");
          //   console.log("Processing your data : Thumbnails, tags, embeddings");
          //   console.log("Please wait !!!");
          //   setTimeout(async () => {
          //     const { data: update, error } = await supabase
          //       .from("user")
          //       .update({ show_instagram: true, is_instagram_on: true })
          //       .eq("user_id", userData.user_id);
          //   },post_ids.length * 1000);
          //   console.log("updates user");
        }
      }, post_ids.length * 1000);
    }
  }
  return (
    <div>
      {" "}
      <h5>Selective Embedding</h5>
      <input
        required
        className="category-input input-value"
        type="text"
        placeholder="enter post id"
        onChange={(e: any) => setPostId(e.target.value)}
        value={postId}
      />
      {/* <h6>Upload folder</h6> */}
      {/* <input type="file" multiple onChange={handleHighlightFolderChange} /> */}
      <Button
        type="primary"
        className="primary-color"
        onClick={generateEmbedding}
      >
        Generate
      </Button>
    </div>
  );
};

export default SingleEmbedding;
