import { useEffect, useRef, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";
import { supabase } from "../../../Config/SupabaseClient";
import {
  fetchAllUsernames,
  getUserData,
  updateIncludeUser,
} from "../../../SupabaseServices/UserDatabase";
import axios from "axios";
import {
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Popconfirm,
  Radio,
  RadioChangeEvent,
  Select,
} from "antd";
import "./creators.css";
import FeedContent from "./ContentTypeForms/FeedContent";
import { ReelsContent } from "./ContentTypeForms/ReelsContent";
import StoryContent from "./ContentTypeForms/StoryContent";
import HighlightsContent from "./ContentTypeForms/HighlightsContent";
import ProfilePicture from "./ContentTypeForms/ProfilePicture";
import NewCreator from "./ContentTypeForms/NewCreator";
import NecUpdate from "./NecUpdate";
import { useLoadCreators } from "./useLoadCreators";
import PostContent from "./ContentTypeForms/PostContent";
import { Space } from "antd";
import YoutubeContent from "./ContentTypeForms/YoutubeContent";
import PodcastContent from "./ContentTypeForms/PodcastContent";
import NewCreatorUpload from "./ContentTypeForms/NewCreatorUpload";
import FeedUpload from "./ContentTypeForms/FeedUpload";
import StoryUpload from "./ContentTypeForms/StoryUpload";
import HighlightsUpload from "./ContentTypeForms/HighlightsUpload";
import TiktokUpload from "./ContentTypeForms/TiktokUpload";
import SingleEmbedding from "./ContentTypeForms/SingleEmbedding";
import ShoplinkUpload from "./ContentTypeForms/ShoplinkUpload";
import { TwitterUpload } from "./ContentTypeForms/TwitterUpload";
import CommentsContent from "./ContentTypeForms/CommentsContent";
import DMsContent from "./ContentTypeForms/DMsContent";
import TrendingProductsContent from "./ContentTypeForms/TrendingProducts";
import ProductBankUpload from "./ContentTypeForms/ProductBankUpload";
import ProductsContent from "./ContentTypeForms/Product";
import "./creators.css";
import CreatorPagination from "./CreatorPagination";
import UsernameUpload from "./NewUploadFlow/UsernameUpload";
import UpdatePostsModal from "./UpdatePostsModal";

const Creators = () => {
  const [selectedUser, setSelectedUser] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isUpdatePostModalOpen, setIsUpdatePostModalOpen] = useState(false);

  const [selectedContentType, setSelectedContentType] = useState("");
  const [showNewCreatorModal, setShowNewCreatorModal] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const {
    isLoading: userLoading,
    data: userData,
    refetch: fetchUsers,
  }: any = useLoadCreators();

  const onChange = (e: RadioChangeEvent) => {
    setSelectedContentType(e.target.value);
  };

  async function regenerateEmbedding() {
    console.log("Generating thumbnails", selectedUser);
    const { data: user_id }: any = await supabase
      .from("user")
      .select("*")
      .eq("username", selectedUser);
    const url = `https://dev.thesearchbubble.com/regenerate/embed`;

    const res = await axios.post(
      url,
      { id: user_id[0].user_id, user: user_id[0].username },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    if (res.data === "done") {
      alert(`Generating Thumbnail!`);
      // setIsModalOpen(false);
    } else {
      alert("Error Generating Thumbnail");
      // setIsModalOpen(false);
    }
  }

  async function generateThumbnails() {
    console.log("Generating thumbnails", selectedUser);

    const { data: user_id }: any = await supabase
      .from("user")
      .select("*")
      .eq("username", selectedUser);

    const url = `https://dev.thesearchbubble.com/generate/carousel/thumbnail`;
    // const url = `http://localhost:3001/fetch/thumbnail`;

    const res = await axios.post(
      url,
      {
        id: user_id[0].user_id,
        user: user_id[0].username,
        OnlyThumbnail: true,
      },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    if (res.data === "done") {
      alert(`Generating Thumbnail!`);
      // setIsModalOpen(false);
    } else {
      alert("Error Generating Thumbnail");
      // setIsModalOpen(false);
    }
    // const { data: user_id }: any = await supabase
    //   .from("user")
    //   .select("*")
    //   .eq("username", selectedUser);
    // await createThumbnails(user_id[0].user_id, user_id[0].username).then(()=>{
    //   alert(`Thumbnails Generated!`);
    //   setIsModalOpen(false);
    // })
  }

  async function generateOcrText() {
    const { data: user_id }: any = await supabase
      .from("user")
      .select("*")
      .eq("username", selectedUser);

    const url = `https://dev.thesearchbubble.com/fetch/ocr`;

    // `https://dev.thesearchbubble.com/api/user/delete`;
    const res = await axios.post(
      url,
      { id: user_id[0].user_id, OnlyOCR: true },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    if (res.data === "done") {
      alert(`Generating OCR!`);
      // setIsModalOpen(false);
    } else {
      alert("Error Generating OCR");
      // setIsModalOpen(false);
    }
  }

  async function generateTags() {
    const { data: user_id }: any = await supabase
      .from("user")
      .select("*")
      .eq("username", selectedUser);

    const url = `https://dev.thesearchbubble.com/fetch/tags`;
    // const url = `http://localhost:3001/fetch/tags`;

    const res = await axios.post(
      url,
      { id: user_id[0].user_id, OnlyTag: true },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    if (res.data === "done" || res.data === "No posts") {
      alert(`Generating tags`);
      //setIsModalOpen(false);
    } else {
      alert("Error Generating Tags");
      //setIsModalOpen(false);
    }
  }

  async function generateTopHashtags() {
    alert("Generating Hashtags");

    const { data: user_id }: any = await supabase
      .from("user")
      .select("*")
      .eq("username", selectedUser);

    const { data: content }: any = await supabase
      .from("content_new")
      .select("*")
      .eq("user_id", user_id[0].user_id);

    const mediaLength = content.length;
    const hashtags: any[] = [];

    content.map((media: any) => {
      if (media.caption) {
        const hashtagsArray = media.caption.match(/#\w+/g);
        if (hashtagsArray && hashtagsArray.length) {
          hashtagsArray.map((hash: any) => {
            hashtags.push(hash);
          });
        }
      }
    });

    const occurrences = hashtags.reduce(function (acc: any, curr: number) {
      return acc[curr] ? (acc[curr] += 1) : (acc[curr] = 1), acc;
    }, {});

    // Introduce delay between each iteration in map
    for (const obj of Object.keys(occurrences)) {
      await delay(50); // 1 second delay

      const usage: any = (occurrences[obj] / mediaLength).toFixed(2);

      const { data, error: erroor }: any = await supabase
        .from("searches")
        .select("*")
        .eq("query", obj)
        .eq("user_id", user_id[0].user_id);

      if (data) {
        if (data.length === 0) {
          await supabase.from("searches").insert([
            {
              query: obj,
              occurrences: occurrences[obj],
              user_id: user_id[0].user_id,
              clicked: 0,
              type: "hashtag",
              usage,
            },
          ]);
        } else {
          await supabase
            .from("searches")
            .update({
              occurrences: occurrences[obj],
              usage,
            })
            .eq("query", obj)
            .eq("user_id", user_id[0].user_id);
        }
      }
    }

    alert("Top Hashtags generation done.");
  }

  // Function to introduce delay
  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  async function uploadToS3() {
    console.log("s3", selectedUser);
    const { data: del } = await supabase
      .from("nec_response")
      .delete()
      .eq("username", selectedUser);
    const { data: user_id }: any = await supabase
      .from("user")
      .select("*")
      .eq("username", selectedUser);
    const url = `https://dev.thesearchbubble.com/uploads3`;

    await axios.post(
      url,
      { user: user_id[0].username, id: user_id[0].user_id },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
  }

  async function framesProcess() {
    console.log("frames", selectedUser);
    const { data: user_id }: any = await supabase
      .from("user")
      .select("*")
      .eq("username", selectedUser);
    const url = `https://dev.thesearchbubble.com/api/frames`;

    await axios.post(
      url,
      { user: user_id[0].username, id: user_id[0].user_id },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
  }

  async function combineAllText() {
    const { data: user_id }: any = await supabase
      .from("user")
      .select("*")
      .eq("username", selectedUser);

    const url = `https://dev.thesearchbubble.com/combine`;

    // `https://dev.thesearchbubble.com/api/user/delete`;
    const res = await axios.post(
      url,
      { id: user_id[0].user_id, user: user_id[0].username },
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    if (res.data === "done") {
      alert(`combined Text!`);
      //setIsModalOpen(false);
    } else {
      alert("Error combining");
      //setIsModalOpen(false);
    }
  }

  const [isResetModalOpen, setIsResetModalOpen] = useState(false);

  const showResetModal = () => {
    setIsResetModalOpen(true);
  };

  const handleResetOk = async () => {
    const { data: user_id }: any = await supabase
      .from("user")
      .select("*")
      .eq("username", selectedUser);
    console.log("reset", selectedUser, user_id);
    await supabase
      .from("analytics")
      .delete()
      .eq("user_id", user_id[0].user_id)
      .then(() => {
        setIsResetModalOpen(false);

        alert(`Done Reset Analytics for ${selectedUser}!`);
      });
  };

  const handleResetCancel = () => {
    setIsResetModalOpen(false);
  };

  const radioOptions = [
    "Profile Picture",
    "Post",
    "Story",
    "Highlights",
    "Trending Products",
    "Products",
  ];
  const contentComponents: any = {
    "Profile Picture": (
      <ProfilePicture user={selectedUser} setIsModalOpen={setIsModalOpen} />
    ),
    Post: <PostContent user={selectedUser} setIsModalOpen={setIsModalOpen} />,
    Story: <StoryContent user={selectedUser} setIsModalOpen={setIsModalOpen} />,
    Highlights: (
      <HighlightsContent user={selectedUser} setIsModalOpen={setIsModalOpen} />
    ),
    Trending: (
      <TrendingProductsContent
        user={selectedUser}
        setIsModalOpen={setIsModalOpen}
      />
    ),
    Products: (
      <ProductsContent
        user={selectedUser}
        setIsProductModalOpen={setIsProductModalOpen}
        setSelectedContentType={setSelectedContentType}
      />
    ),
  };
  return (
    <>
      {userLoading ? (
        <PuffLoader
          color="#5274f9"
          loading={userLoading}
          className="loader"
          size={150}
        />
      ) : (
        <div className="d-side">
          <div className="details">
            <h1 className="dashboard-mobile-hide">Creators</h1>
            <Button
              type="primary"
              className="primary-color"
              onClick={() => setShowNewCreatorModal(true)}
            >
              Create New Creator
            </Button>

            <CreatorPagination
              userData={userData}
              fetchUsers={fetchUsers}
              setSelectedUser={setSelectedUser}
              showResetModal={showResetModal}
              setIsModalOpen={setIsModalOpen}
              setIsUpdatePostModalOpen={setIsUpdatePostModalOpen}
            />
            {/* </table> */}
          </div>
        </div>
      )}
      <Modal
        title={
          <>
            Upload Content for{" "}
            <a
              href={`https://www.searchbubble.com/${selectedUser}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {selectedUser}
            </a>
          </>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <NecUpdate username={selectedUser} />

        <h5>Select what type of content you need to upload</h5>

        {/* webkitdirectory mozdirectory directory */}
        <br />

        <CommentsContent username={selectedUser} />
        <hr />
        <br />
        <DMsContent username={selectedUser} />
        <hr />
        <br />

        <FeedUpload username={selectedUser} />
        <br />
        <HighlightsUpload username={selectedUser} />

        <hr />

        <StoryUpload username={selectedUser} />
        <hr />
        <br />

        <TiktokUpload username={selectedUser} />
        <hr />
        <br />

        <TwitterUpload username={selectedUser} />
        <hr />
        <br />
        <h5>Upload Youtube data</h5>

        <YoutubeContent selectedUser={selectedUser} />
        <hr />

        <br />
        <h5>Upload Podcast data</h5>

        <PodcastContent selectedUser={selectedUser} />
        <hr />
        <br />
        <h5>Upload Product Bank data</h5>

        <ProductBankUpload selectedUser={selectedUser} />
        <hr />
        <h5 className="text-center">or</h5>
        <h5>Upload content individually</h5>

        <Radio.Group onChange={onChange} buttonStyle="solid">
          {radioOptions.map((option) => (
            <Radio.Button
              key={option}
              style={{
                backgroundColor:
                  selectedContentType === option ? "#414141" : "#fff",
              }}
              value={option}
            >
              {option}
            </Radio.Button>
          ))}
        </Radio.Group>
        {contentComponents[selectedContentType] || ""}

        <hr />
        <h6>Generate Top Hashtags</h6>

        <Button
          type="primary"
          className="primary-color"
          onClick={generateTopHashtags}
        >
          Generate Top Hashtags
        </Button>
        <hr />
        <h6>Regenerate Embedding</h6>

        <Button
          type="primary"
          className="primary-color"
          onClick={regenerateEmbedding}
        >
          Regenerate Embedding
        </Button>
        <hr />
        <h6>Generate Thumbnails</h6>
        <Button
          type="primary"
          className="primary-color"
          onClick={generateThumbnails}
        >
          Generate thumbnails
        </Button>
        <hr />
        <h6>Generate ocr Text</h6>
        <Button
          type="primary"
          className="primary-color"
          onClick={generateOcrText}
        >
          Generate ocr Text
        </Button>
        <hr />
        <h6>Generate Tags</h6>
        <Button type="primary" className="primary-color" onClick={generateTags}>
          Generate Tags
        </Button>
        <hr />
        <h6>Upload to S3 server</h6>
        <Button type="primary" className="primary-color" onClick={uploadToS3}>
          Upload to S3
        </Button>
        <hr />
        <h6>Process Frames</h6>
        <Button
          type="primary"
          className="primary-color"
          onClick={framesProcess}
        >
          Process Frames{" "}
        </Button>
        <hr />
        <SingleEmbedding username={selectedUser} />
      </Modal>

      {/* <NewCreatorUpload
        showNewCreatorModal={showNewCreatorModal}
        setShowNewCreatorModal={setShowNewCreatorModal}
        fetchUsers={fetchUsers}
        setIsModalOpen={setIsModalOpen}
        setSelectedUser={setSelectedUser}
      /> */}
       <UsernameUpload
        showNewCreatorModal={showNewCreatorModal}
        setShowNewCreatorModal={setShowNewCreatorModal}
        fetchUsers={fetchUsers}
        setIsModalOpen={setIsModalOpen}
        setSelectedUser={setSelectedUser}
      />

      <Modal
        title="Reset Analytics"
        open={isResetModalOpen}
        onOk={handleResetOk}
        onCancel={handleResetCancel}
        okText="Reset"
      >
        <p>
          Are you sure you want to reset <b>{selectedUser}</b>'s Analytics?
        </p>
      </Modal>

      <UpdatePostsModal
        selectedUser={selectedUser}
        isUpdatePostModalOpen={isUpdatePostModalOpen}
        setIsUpdatePostModalOpen={setIsUpdatePostModalOpen}
        setSelectedUser={setSelectedUser}
      />
    </>
  );
};

export default Creators;
