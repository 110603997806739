import React, { useState } from "react";
import { useQuery } from "react-query";
import {
  fetchIncludedUsername,
  fetchIncludedUsers,
} from "../../../SupabaseServices/UserDatabase";
import { useLoadProductBankPages } from "./useLoadProductBankPages";
import { useLoadProductBankContent } from "./useLoadProductBankContent";
import {
  Button,
  DatePicker,
  DatePickerProps,
  Input,
  Modal,
  Pagination,
  Upload,
  UploadProps,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { supabase } from "../../../Config/SupabaseClient";
import { addProduct } from "../../../SupabaseServices/ProductBankDatabase";
import ProductContent from "./ProductContent";
import PuffLoader from "react-spinners/PuffLoader";
import { Select, Space } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
// import OpenAI from 'openai';

import { Configuration, OpenAIApi } from "openai";
import axios from "axios";

dayjs.extend(utc);

const POSTS_PER_PAGE = 25;
const ProductBank = () => {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [image, setImage] = useState("");
  const [platform, setPlatform] = useState("");
  const [category, setCategory] = useState("");
  const [createdDateOriginal, setCreatedDateOriginal] = useState<any>("");
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState([]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const platformItems = [
    { value: "Amazon", label: "Amazon" },
    { value: "LTK", label: "LTK" },
    { value: "ShopMy", label: "ShopMy" },
    { value: "instagram", label: "instagram" },
    { value: "Youtube", label: "Youtube" },
  ];
  const handleOk = async () => {
    const createdDate = dayjs(createdDateOriginal || new Date()).format(
      "YYYY-MM-DD HH:mm:ss.SSSSSS[+00]"
    );
 
    await addProduct(
      name,
      link,
      image,
      selectedIds[0],
      platform?platform:"MISC",
      category,
      createdDate,
      usernames[0]
    );
    // try {
    //   const embedUrl = `https://dev.thesearchbubble.com/embedProduct`;
    //   // const embedUrl = `http://localhost:3001/embedProduct`;

    //   const res = axios.post(
    //     embedUrl,
    //     {
    //       user: usernames[0],
    //     },
    //     {
    //       // headers: {
    //       //   "content-type": "application/json",
    //       // },
    //     }
    //   );
    //   // return res.data.embedding;
    // } catch (error) {}
    refetchCount();
    refetch();
    setName("");
    setLink("");
    setImage("");
    setPlatform("");
    setCategory("");
    setFileList([]);
    setCreatedDateOriginal(null);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setName("");
    setLink("");
    setImage("");
    setPlatform("");
    setCategory("");
    setFileList([]);
    setCreatedDateOriginal(null);

    setIsModalOpen(false);
  };
  const [usernames, setUsernames] = useState<any>([]);
  const [selectedCreator, setSelectedCreator] = useState<any>();
  const [selectedIds, setSelectedIds] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);

  useQuery({
    queryFn: () => {
      fetchUsers();
    },
    refetchOnMount: true,
  });
  async function fetchUsers() {
    const users = await fetchIncludedUsername();
    const userIds = await fetchIncludedUsers();
    setSelectedIds(userIds);
    setSelectedCreator(users);
    setUsernames(users);
  }
  const { data: pages, refetch: refetchCount } = useLoadProductBankPages(
    selectedIds?.[0] || "",

    POSTS_PER_PAGE,
    searchQuery,
    usernames?.[0] || ""
  );

  const {
    data: posts,
    isLoading: postsLoading,
    isError,
    error,
    refetch,
  }: any = useLoadProductBankContent(
    selectedIds?.[0] || "",
    usernames?.[0] || "",
    currentPage,
    POSTS_PER_PAGE,
    pages?.totalPages,
    searchQuery
  );
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };
  const handleInputChange = (value: string, setter: Function) => {
    setter(value);
  };
  const uploadProps: UploadProps<any> = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    async onChange(info: any) {
      info.file.status = "uploading";

      let fileList: any = [...info.fileList];

      fileList = fileList.slice(-1);

      // 2. Read from response and show file link
      fileList = fileList.map((file: any) => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
          file.staus = "uploading";
        }
        return file;
      });
      setFileList(fileList);

      setTimeout(() => {
        info.file.status = "done";
      }, 0);

      const { data: highlightslist }: any = await supabase.storage
        .from("product_bank")
        .list(`${usernames?.[0]}/${name}`, { limit: 5000 });
      for (let item of highlightslist) {
        await supabase.storage
          .from("product_bank")
          .remove([`${usernames?.[0]}/${name}/${item.name}`]);
      }
      const post_id = info.file.name.split("_")[0];
      const { data, error }: any = await supabase.storage
        .from("product_bank")
        .upload(
          `${usernames?.[0]}/${name}/${info.file.type.split("/")[0]}${post_id}`,
          info.file.originFileObj,
          {
            contentType: `${info.file.type}`,
          }
        );

      var { data: url }: any = await supabase.storage
        .from("product_bank")
        .getPublicUrl(
          `${usernames?.[0]}/${name}/${info.file.type.split("/")[0]}${post_id}`
        );
      setImage(url.publicUrl);
      handleInputChange(url.publicUrl, setImage);
      // props.handleData(url.publicUrl, name, postlink,"content");
      if (info.file.status === "done") {
      } else if (info.file.status === "error") {
      }
    },
  };
  const handleChange = (value: string) => {
    setPlatform(value);
  };
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    if (date) {
      setCreatedDateOriginal(date);
    } else {
      setCreatedDateOriginal(null);
    }
  };

  const handleSearch = (value: any) => {
    setCurrentPage(1)
    setSearchQuery(value);
  };
  async function handleShortenName(value: any) {
    const openAiClient = new OpenAIApi(
      new Configuration({
        apiKey: "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", // Replace with your actual key
      })
    );
    // const openAiClient = new OpenAIApi(configuration);
    const instruction = "Condense into 5 words or less";

    try {
      const response: any = await openAiClient.createChatCompletion({
        model: "gpt-4",
        messages: [
          {
            role: "system",
            content:
              "You are an assistant that rewrites or adjusts text based on user instructions.",
          },
          {
            role: "user",
            content: `Here is the original text:\n"${value}"\n\nFollow this instruction:\n"${instruction}"`,
          },
        ],
        max_tokens: 2000, // Adjust as needed
      });

      let modifiedText = response.data.choices[0].message.content;
      if (modifiedText.startsWith('"') && modifiedText.endsWith('"')) {
        modifiedText = modifiedText.slice(1, -1);
      }
      // return modifiedText;
      setName(modifiedText);
    } catch (error: any) {
      console.error(
        "Error with OpenAI API:",
        error.response?.data || error.message
      );
    }
  }

  // Filter posts based on search query
console.log("pagess",pages)
  return (
    <>
      <Button className="add-product" onClick={showModal}>
        Add Product
      </Button>
      <Input.Search
        placeholder="Search products"
        allowClear
        onSearch={handleSearch}
        style={{ margin: "20px 0", width: "100%" }}
      />
      <Modal
        title="Add Product To Product Bank"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Add"
        okButtonProps={{ disabled: !(name && link && image) }}
      >
        <label className="font-bold">Product Name</label>
        <Input
          className="mb-2"
          placeholder="Enter Product Name"
          value={name}
          onChange={(e: any) => {
            // handleShortenName(e.target.value);
            handleInputChange(e.target.value, setName);
          }}
        />
        <label className="font-bold">Product Link</label>
        <Input
          className="mb-2"
          placeholder="Enter Product Link"
          value={link}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setLink);
          }}
        />
        <label className="font-bold">Product Category</label>

        <Input
          className="mb-2"
          placeholder="Enter Category"
          value={category}
          onChange={(e: any) => {
            handleInputChange(e.target.value, setCategory);
          }}
        />
        <label className="font-bold">Platform</label>
        <Select
          defaultValue={platform}
          placeholder="Select Platform"
          style={{ width: 120 }}
          onChange={handleChange}
          options={platformItems}
        />
        <div>
          <label className="font-bold">Date</label>
          <Space direction="vertical">
            <DatePicker
              onChange={onChange}
              defaultValue={createdDateOriginal}
              value={createdDateOriginal} // Set the default value
            />
          </Space>
        </div>

        <div className="modal-image-upload">
          <img src={image} className="img-style" />
          <Upload
            {...uploadProps}
            maxCount={1}
            className="img-upload"
            showUploadList={fileList.length > 0 ? true : false}
          >
            <Button
              icon={<UploadOutlined />}
              disabled={!name}
              defaultValue={name}
            >
              Click to Upload
            </Button>
          </Upload>
        </div>
      </Modal>

      <div className="d-side">
        <div>
          {selectedCreator && selectedCreator.length && (
            <>Selected Creator :- {usernames[0]}</>
          )}
        </div>
        <div className="product-bank-content">
          {selectedCreator && selectedCreator.length ? (
            <>
              <ProductContent
                username={usernames[0]}
                user_id={selectedIds[0]}
                refetch={refetch}
                posts={posts}
                currentPage={currentPage}
              />

              <Pagination
                showQuickJumper
                current={currentPage}
                pageSize={POSTS_PER_PAGE}
                total={pages?.feedCount}
                onChange={handlePageChange}
                showSizeChanger={false}
                className="pagination"
              />
            </>
          ) : (
            //

            <PuffLoader
              color="#5274f9"
              //   loading={userLoading}
              className="loader"
              size={150}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProductBank;
